<template>
  <div class="SystemsView">
    <div class="systems">
      <div
        v-for="systemId of systemIds"
        :key="systemId"
        class="System">
        <div class="System__header">
          {{ systemId }}
        </div>
        <div class="System__body">
          <div
            class="Button"
            @click="gotoSystem(systemId)">
            Open
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            systemIds: ['single', 'rhyme', 'alphabet', 'double', 'olpa', 'journey', 'name']
        };
    },
    methods: {
        gotoSystem(systemId) {
            this.$router.push({
                path: `/system/${systemId}`
            });
        }
    }
};
</script>

<style scoped lang="scss">
.SystemsView {
    padding: 1em;
    background-color: black;
    color: white;
    overflow-y: auto;
}
.System {
    margin: 1em auto;
    border: 1px solid black;
    background-color: rgba(white, 0.2);
    max-width: 30em;
}
.System__header {
    text-align: center;
    border-bottom: 1px solid rgba(black, 0.1);
    padding: 1em;
}
.System__body {
    padding: 1em;
}

.Button {
    background-color: white;
    color: black;
    border-radius: 2em;
    padding: 0.3em 1em;
}
</style>
